// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-foundation-tsx": () => import("./../src/pages/foundation.tsx" /* webpackChunkName: "component---src-pages-foundation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logos-tsx": () => import("./../src/pages/logos.tsx" /* webpackChunkName: "component---src-pages-logos-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-support-help-guide-tsx": () => import("./../src/pages/support/help-guide.tsx" /* webpackChunkName: "component---src-pages-support-help-guide-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-support-watch-tutorials-tsx": () => import("./../src/pages/support/watch-tutorials.tsx" /* webpackChunkName: "component---src-pages-support-watch-tutorials-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

