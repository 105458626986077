module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Makersflow","short_name":"Makersflow","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MW962LN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src4034624776/src/makersflow-gatsby/src/locales/","languages":["bn","de","en","es","fr","hi","id","it","ja","ko","pt","tr","vi","zh-cn","zh-tw"],"defaultLanguage":"en","i18nextOptions":{"ns":["translations","cms"],"defaultNS":"translations","lowerCaseLng":true,"interpolation":{"escapeValue":false},"debug":true}},
    }]
